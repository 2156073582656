import axios from "axios";
import { alias } from "../../api";
import Vue from 'vue'
import Cookies from 'js-cookie'

const state = {
    data: [],
    filters: {}
};

const getters ={}

// mutations
const mutations = {
    FETCH_ALL_ALIASES(state, data) {
        if(data){
            state.data = data.data;
            
            let filters = data.filters
            let result = []
            Object.keys(filters)
            .forEach(function eachKey(key) { 
                let items = []
                Object.keys(filters[key])
                    .forEach(function eachKey(k) { 
                        items.push(filters[key][k])
                        //console.log(filters[key][k])
                    });
                let data = {
                    name:key,
                    items:items,
                    selected_filter:[]
                   
                }
                result.push(data)
            });
           state.filters = result
        }
    }
};

const actions ={
    fetchAll({ commit }) {
//        console.log("Fetch Users")
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        return axios
            .get(`${alias}`)
            .then(response => commit("FETCH_ALL_ALIASES", response.data))
            .catch();
    },
    async addAlias({ commit, dispatch }, data) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        await axios
            .post(`${alias}`, data)
            .then(function (response) {
                Vue.notify({
                    group: 'notify',
                    type: 'success',
                    text: 'Alias has been created',
                    max: 5,
                    duration: 5000,
                    speed: 300
                })
                dispatch("fetchAll")
            })
            .catch((error) => {
                // alert(error)
                // console.log(error.response)

                if (error.response) {
                    //alert(error.response.data.message)
                    Vue.notify({
                        group: 'notify',
                        type: 'error',
                        text: error.response.data.message,
                        max: 5,
                        duration: 5000,
                        speed: 300
                    })
                } else {
					Vue.notify({
                        group: 'notify',
                        type: 'error',
                        text: 'Problem creating new alias.',
                        max: 5,
                        duration: 5000,
                        speed: 300
                    });
                }
            });
    },
    async updateAlias({ commit, dispatch }, data) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        await axios
            .patch(`${alias}/${data.id}`, data)
            .then((response) => {
                Vue.notify({
                    group: 'notify',
                    type: 'success',
                    text: 'Alias status has been updated',
                    max: 5,
                    duration: 5000,
                    speed: 300
                });
				dispatch("fetchAll");
            })
            .catch((error) => {
                if (error.response) {
                    Vue.notify({
                        group: 'notify',
                        type: 'error',
                        text: error.response.data.message,
                        max: 5,
                        duration: 5000,
                        speed: 300
                    })
                } else {
                    Vue.notify({
                        group: 'notify',
                        type: 'error',
                        text: 'Problem updating alias.',
                        max: 5,
                        duration: 5000,
                        speed: 300
                    });
                }
                dispatch("fetchAll");
            });

    },
    async deleteAlias({ commit, dispatch }, id) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        await axios
            .delete(`${alias}/${id}`)
            .then((response) => {
//                console.log(response)
                dispatch("fetchAll");
                Vue.notify({
                    group: 'notify',
                    type: 'success',
                    text: 'Alias has been deleted',
                    max: 5,
                    duration: 5000,
                    speed: 300
                });
            })
            .catch((error) => {
                if (error.response) {
                    Vue.notify({
                        group: 'notify',
                        type: 'error',
                        text: error.response.data.message,
                        max: 5,
                        duration: 5000,
                        speed: 300
                    })
                } else {
                    console.log("Problem submitting New Post", error);
                }
            });
    },
}

export { state, getters, actions, mutations };
