import axios from 'axios';
import Cookies from 'js-cookie';

axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');

const apiBaseUrl = 'https://lorq-api.thelearninglab.com.sg/'; //live
//const apiBaseUrl = 'http://api.lorq.thelearninglab.com.sg/'; //local
const login = apiBaseUrl + 'api/login';
const tags = apiBaseUrl + 'api/tags';
const questions = apiBaseUrl +'api/questions';
const bulk_questions = apiBaseUrl +'api/questions/bulk';
const courses = apiBaseUrl +'api/courses';
const users = apiBaseUrl +'api/users';
const getAuthUser = apiBaseUrl + 'api/get-user';
const logout = apiBaseUrl + 'api/logout';
const analytics = apiBaseUrl +'api/analytics';
const analytics_tags = apiBaseUrl +'api/analytics/tags';
const analytics_export = apiBaseUrl +'api/analytics/export';

const activity = apiBaseUrl +'api/activity';
const oauthUrl = apiBaseUrl + 'api/oauth';
const userroles = apiBaseUrl + 'api/users/roles'

const alias = apiBaseUrl + 'api/aliases'
const possible_duplicates = apiBaseUrl + 'api/possible-duplicates'

export {users, tags, questions,bulk_questions, courses, analytics, analytics_tags, analytics_export, activity, login, getAuthUser, logout, oauthUrl, userroles, alias, possible_duplicates};