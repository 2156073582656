import axios from "axios";
import { possible_duplicates } from "../../api";
import Vue from 'vue'
import Cookies from 'js-cookie'
import router from '../../router'

const state = {
    duplicates: [],
    total:0,
    total_revert:0,
    duplicates_revert:[],
    duplicates_deleted:[],
    total_deleted:0,
	per_page:0,
}

const getters = {}

const mutations = {
    FETCH_DUPLICATES(state, data) {
        if(data){
            state.duplicates = data.data;
            state.total = data.total;
			state.per_page = data.per_page;
        }
    },
    FETCH_DUPLICATES_REVERT(state, data) {
        if(data){
            state.duplicates_revert = data.data;
            state.total_revert = data.total;
			state.per_page = data.per_page;
        }
    },
    FETCH_DUPLICATES_DELETED(state, data) {
        if(data){
            state.duplicates_deleted = data.data;
            state.total_deleted = data.total;
			state.per_page = data.per_page;
        }
    }
}

const actions = {
    fetchDuplicates({ commit }, data) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        var query = `${possible_duplicates}`;
        if (data.query.page) {
            query += `?page=${data.query.page}`;

        } else if (data.query.page) {
            query += `?page=${data.query.page}`;
        } 

        return axios.get(query)
            .then(response => commit('FETCH_DUPLICATES', response.data))
            .catch();
    },
    fetchDuplicatesRevert({ commit }, data) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        var query = `${possible_duplicates}/revert`;
        if (data.query.page) {
            query += `?page=${data.query.page}`;

        } else if (data.query.page) {
            query += `?page=${data.query.page}`;
        } 

        return axios.get(query)
            .then(response => commit('FETCH_DUPLICATES_REVERT', response.data))
            .catch();
    },
    fetchDuplicatesDeleted({ commit }, data) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        var query = `${possible_duplicates}/deleted`;
        if (data.query.page) {
            query += `?page=${data.query.page}`;

        } else if (data.query.page) {
            query += `?page=${data.query.page}`;
        } 

        return axios.get(query)
            .then(response => commit('FETCH_DUPLICATES_DELETED', response.data))
            .catch();
    },
    async deduplicateQuestion({ commit, dispatch }, data) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        await axios
            .post(`${possible_duplicates}`, data)
            .then((response) => {
				if (router.currentRoute.query.hasOwnProperty('page')) {
					dispatch("fetchDuplicates", {
						query: {
							page: router.currentRoute.query.page
						}
					});
				} else {
					dispatch("fetchDuplicates", {query: {page: 1}});
				}
                Vue.notify({
                    group: 'notify',
                    type: 'success',
                    text: 'Selected Question has been kept!',
                    max: 5,
                    duration: 5000,
                    speed: 300
                });
            })
            .catch((error) => {
                if (error.response) {
                    Vue.notify({
                        group: 'notify',
                        type: 'error',
                        text: error.response.data.error.message,
                        max: 5,
                        duration: 5000,
                        speed: 300
                    })
                } else {
                    console.log("Problem submitting New Post", error);
                }
            });

    },
    async deleteQuestion({ commit, dispatch }, data) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        await axios
            .delete(`${possible_duplicates}/deleted/${data.id}`)
            .then((response) => {
				if (router.currentRoute.query.hasOwnProperty('page')) {
					dispatch("fetchDuplicates", {
						query: {
							page: router.currentRoute.query.page
						}
					});
				} else {
					dispatch("fetchDuplicates", {query: {page: 1}});
				}
                Vue.notify({
                    group: 'notify',
                    type: 'success',
                    text: 'Questions have been removed from deduplication!',
                    max: 5,
                    duration: 5000,
                    speed: 300
                });
            })
            .catch((error) => {
                if (error.response) {
                    Vue.notify({
                        group: 'notify',
                        type: 'error',
                        text: error.response.data.error.message,
                        max: 5,
                        duration: 5000,
                        speed: 300
                    })
                } else {
                    console.log("Problem submitting New Post", error);
                }
            });

    },
    async revert({ commit, dispatch }, data) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        await axios
            .post(`${possible_duplicates}/revert`, data)
            .then((response) => {
				if (router.currentRoute.query.hasOwnProperty('page')) {
					dispatch("fetchDuplicatesRevert", {
						query: {
							page: router.currentRoute.query.page
						}
					});
				} else {
					dispatch("fetchDuplicatesRevert", {query: {page: 1}});
				}
                Vue.notify({
                    group: 'notify',
                    type: 'success',
                    text: 'Selected Question has been reverted!',
                    max: 5,
                    duration: 5000,
                    speed: 300
                });
            })
            .catch((error) => {
                if (error.response) {
                    Vue.notify({
                        group: 'notify',
                        type: 'error',
                        text: error.response.data.error.message,
                        max: 5,
                        duration: 5000,
                        speed: 300
                    })
                } else {
                    console.log("Problem submitting New Post", error);
                }
            });

    },
    async revertDeleted({ commit, dispatch }, data) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        await axios
            .post(`${possible_duplicates}/deleted`, data)
            .then((response) => {
				if (router.currentRoute.query.hasOwnProperty('page')) {
					dispatch("fetchDuplicatesDeleted", {
						query: {
							page: router.currentRoute.query.page
						}
					});
				} else {
					dispatch("fetchDuplicatesDeleted", {query: {page: 1}});
				}
                Vue.notify({
                    group: 'notify',
                    type: 'success',
                    text: 'Selected Question has been reverted!',
                    max: 5,
                    duration: 5000,
                    speed: 300
                });
            })
            .catch((error) => {
                if (error.response) {
                    Vue.notify({
                        group: 'notify',
                        type: 'error',
                        text: error.response.data.error.message,
                        max: 5,
                        duration: 5000,
                        speed: 300
                    })
                } else {
                    console.log("Problem submitting New Post", error);
                }
            });

    },
}

export { state, getters, actions, mutations };
