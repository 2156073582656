<template>
  <div id="app">
      <loading ref="loading"/>
      <notifications group="notify"/>
      <router-view></router-view>
  </div>
</template>

<script>
import Loading from './Loading.vue';

export default {
  name: 'App',
  components: {
    Loading
  },
  mounted () {
    this.$loading = this.$refs.loading
  },
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
//   .multiselect--above .multiselect__content-wrapper {
//     border-bottom-left-radius: 0;
//     border-bottom-right-radius: 0;
//     border-top-left-radius: 5px;
//     border-top-right-radius: 5px;
//     border-bottom: none;
//     border-top: 1px solid #e8e8e8;
// }

// .multiselect__option--highlight {
//     background: #428bca !important;
// }

// .multiselect__option--highlight:after {
//   background: #428bca !important;
// }
// .multiselect__tag{
//   background: #428bca !important;
// }
// .multiselect__tag-icon:after {
//   color: rgba(60, 60, 60, 0.5) !important;
// }
</style>