import axios from "axios";
import { users, userroles } from "../../api";
import Vue from 'vue'
import Cookies from 'js-cookie'


// state
const state = {
    all: [],
    roles: []
};

// getters
const getters = {};

// mutations
const mutations = {
    FETCH_ALL_USERS(state, users) {
        state.all = users;
    },
    FETCH_ROLES(state, roles) {
        state.roles = roles;
    }
};

// actions
const actions = {
    fetchRoles({ commit }) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        return axios
            .get(`${userroles}`)
            .then(response => commit("FETCH_ROLES", response.data))
            .catch();
    },
    fetchAllUsers({ commit }) {
//        console.log("Fetch Users")
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        return axios
            .get(`${users}`)
            .then(response => commit("FETCH_ALL_USERS", response.data))
            .catch();
    },
    async deleteUser({ commit, dispatch }, id) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        await axios
            .delete(`${users}/${id}`)
            .then((response) => {                //commit("FETCH_ALL_USERS", response.data)
                dispatch("fetchAllUsers");
                Vue.notify({
                    group: 'notify',
                    type: 'success',
                    text: 'User has been deleted',
                    max: 5,
                    duration: 5000,
                    speed: 300
                });
            })
            .catch((error) => {
                if (error.response) {
                    Vue.notify({
                        group: 'notify',
                        type: 'error',
                        text: error.response.data.error.message,
                        max: 5,
                        duration: 5000,
                        speed: 300
                    })
                } else {
                    console.log("Problem submitting New Post", error);
                }
            });
    },
    async updateUser({ commit, dispatch }, data) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        await axios
            .patch(`${users}/${data.id}`, data)
            .then((response) => {
                //commit("FETCH_ALL_USERS", response.data)
                 dispatch("fetchAllUsers");
                Vue.notify({
                    group: 'notify',
                    type: 'success',
                    text: 'Role status has been updated',
                    max: 5,
                    duration: 5000,
                    speed: 300
                });
            })
            .catch((error) => {
                if (error.response) {
                    Vue.notify({
                        group: 'notify',
                        type: 'error',
                        text: error.response.data.error.message,
                        max: 5,
                        duration: 5000,
                        speed: 300
                    })
                } else {
                    console.log("Problem submitting New Post", error);
                }
            });

    },
    async addUser({ commit, dispatch }, data) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        await axios
            .post(`${users}`, data)
            .then(function (response) {
                Vue.notify({
                    group: 'notify',
                    type: 'success',
                    text: 'User has been created',
                    max: 5,
                    duration: 5000,
                    speed: 300
                })
                dispatch("fetchAllUsers")
            })
            .catch((error) => {
                // alert(error)
                // console.log(error.response)

                if (error.response) {
                    //alert(error.response.data.message)
                    Vue.notify({
                        group: 'notify',
                        type: 'error',
                        text: error.response.data.message,
                        max: 5,
                        duration: 5000,
                        speed: 300
                    })
                } else 
                {
                    //console.log("Problem submitting New Post", error);
                    alert("Problem Creating new user")
                }
            });
    }
};

export { state, getters, actions, mutations };
