import axios from 'axios';
import { courses, userroles } from '../../api';
import Cookies from 'js-cookie'
import Vue from 'vue'

// state
const state = {
    active_courses: [],
    non_active_courses:[],
    setting: []
}

// getters
const getters = {

}

// mutations
const mutations = {
    FETCH_ACTIVE_COURSES(state, data){
        if(data){
            state.active_courses = data
        }
    },
    FETCH_COURSES(state, data) {
        //console.log(data)
        if(data){
            state.active_courses = []
            state.non_active_courses = []
            state.active_courses = data.active_courses
            state.non_active_courses = data.non_active_courses
        }
        //state.data = courses;
    },
    FETCH_PERMISSION(state, role) {
        state.setting = role.permission;
    },
}

// actions
const actions = {
    async updateActiveCourses({ commit }, data) {
        commit('FETCH_ACTIVE_COURSES',[])
        commit('FETCH_ACTIVE_COURSES',data)
    },
    async fetchCourses({ commit }) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        return await axios.get(courses)
            .then(response => commit('FETCH_COURSES', response.data))
            .catch();
    },
    async fetchPermission({ commit }, id) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        return await axios.get(`${userroles}/${id}`)
            .then(response => commit('FETCH_PERMISSION', response.data))
            .catch();
    },
    async createActiveCourse({ commit }, data) {
        await axios.post(`${courses}`, data)
            .then((response) => {
                Vue.notify({
                    group: 'notify',
                    type: 'success',
                    text: 'Course has been saved to active courses',
                    max: 5,
                    duration: 5000,
                    speed: 300
                });
            })
            .catch((error) => {
                if (error.response) {
                    Vue.notify({
                        group: 'notify',
                        type: 'error',
                        text: error.response.data.message,
                        max: 5,
                        duration: 5000,
                        speed: 300
                    })
                } else {
                    console.log("Problem submitting New Post", error);
                }
            });
    },
    
    async removeCourse({ commit, dispatch }, id) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        await axios.delete(`${courses}/${id}`)
            .then((response) => {
                Vue.notify({
                    group: 'notify',
                    type: 'success',
                    text: 'Course has been deleted',
                    max: 5,
                    duration: 5000,
                    speed: 300
                });
            })
            .catch((error) => {
                if (error.response) {
                    Vue.notify({
                        group: 'notify',
                        type: 'error',
                        text: error.response.data.error.message,
                        max: 5,
                        duration: 5000,
                        speed: 300
                    })
                } else {
                    console.log("Problem submitting New Post", error);
                }
            });
    },
    async updateCourse({ commit }, data) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        return axios.patch(`${courses}/${data.id}`, {
            id:data.id,
            year: data.year,
            level: data.level,
            subject: data.subject
        }).then((response) => {
                Vue.notify({
                    group: 'notify',
                    type: 'success',
                    text: 'Course has been updated',
                    max: 5,
                    duration: 5000,
                    speed: 300
                });
            });
    },
    // async updateCourseFilter({ commit }, data) {
    //     await axios.put(`${userroles}/${data.id}`, {
    //         data: data.setting
    //     })
    //         .then(() => {
    //             Vue.notify({
    //                 group: 'notify',
    //                 type: 'success',
    //                 text: 'Filter has been saved',
    //                 max: 5,
    //                 duration: 5000,
    //                 speed: 300
    //             });
    //         })
    //         .catch((error) => {
    //             if (error.response) {
    //                 Vue.notify({
    //                     group: 'notify',
    //                     type: 'error',
    //                     text: error.response.data.error.message,
    //                     max: 5,
    //                     duration: 5000,
    //                     speed: 300
    //                 })
    //             } else {
    //                 console.log("Problem submitting New Post", error);
    //             }
    //         });
    // },
}

export {
    state,
    getters,
    actions,
    mutations
}