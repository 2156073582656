import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import Multiselect from 'vue-multiselect'
import store from './store/index'
import '@babel/polyfill'
import Notifications from 'vue-notification'
import './plugins'

import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)
Vue.use(Notifications)

Vue.component('multiselect', Multiselect)

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)

// console.log(store)
new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
