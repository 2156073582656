import axios from 'axios';
import { analytics, analytics_tags, analytics_export } from '../../api';

// state
const state = {
    data: [], // Question Summary
    filters:[],
    tag_data:[],
    tag_filters:[]
}

// getters
const getters = {

}

// mutations
const mutations = {
    FETCH(state, data) {
        if(data)
        {
            state.data = data.data;
            let filters = data.filters
            let result = []
            Object.keys(filters)
            .forEach(function eachKey(key) { 
                let selected_filter = filters[key].filter(obj => {
                    return obj.selected === true
                  });

                let filter_arr = ''
                if(selected_filter.length == 0){
                    filter_arr = filters[key][0]
                }
                else {
                    filter_arr = selected_filter[0]
                }

                let data = {
                    name:key,
                    items:filters[key],
                    selected_filter:filter_arr
                }


                result.push(data)
            });
           state.filters = result

        }
    },
    FETCH_TAGS(state, data) {
        if(data){
            state.tag_data = data.data;
            let filters = data.filters
            let result = []
            Object.keys(filters)
            .forEach(function eachKey(key) { 
                let selected_filter = filters[key].filter(obj => {
                    return obj.selected === true
                  });

                let filter_arr = ''

                if(selected_filter.length == 0){
                    filter_arr = filters[key][0]
                }
                else {
                    filter_arr = selected_filter[0]
                }

                let data = {
                    name:key,
                    items:filters[key],
                    selected_filter:filter_arr
                }
                result.push(data)
            });
           state.tag_filters = result
        }
    }
}

// actions
const actions = {
    async fetch({ commit }, data) {
        return axios.post(analytics, {filters:data.filter})
            .then(response => commit('FETCH', response.data))
            .catch();
    },
    async fetchTags({ commit }, data) {
        return axios.post(analytics_tags, {filters:data.filter})
            .then(response => commit('FETCH_TAGS', response.data))
            .catch();
    },
    async export() {
        return axios.post(analytics_export)
            .then()
            .catch();
    },
}

export {
    state,
    getters,
    actions,
    mutations
}