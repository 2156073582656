import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'
Vue.use(Router)
import store from '../store'


const router = createRouter()

/**
 * Create a new router instance.
 *
 * @return {Router}
 */
 function createRouter() {
    const router = new Router({
        mode: 'history',
        linkActiveClass: 'active',
        scrollBehavior: () => ({ y: 0 }),
        routes: routes
    })

    router.beforeEach(beforeEach)

    //router.afterEach(afterEach)
  
    return router
}


function beforeEach(to, from, next) {
  // Get the matched components and resolve them
  
  if (to.meta.requireAuth) {
    if (isAuthenticated()) {
//      console.log("A")
      next();
    } 
    else 
    {
//      console.log("B")

      if(from.path == '/login')
      {
        next('/');
      }
      else
      {

        next({ path: '/login' });
      }

    }
  }
  next();

}

function isAuthenticated(){
  let isValid = 0

  if (!store.getters['auth/check'] && store.getters['auth/token'])
  {
//    console.log('C')
    try {
      store.dispatch('auth/fetchUser')
      isValid = 1
    } catch (e) { console.log(e) }
  }
  else
  {
//    console.log(store.getters['auth/user'])
      if(store.getters['auth/check']){
        isValid = 1
      } 
      else 
      {
        isValid = 0
      }
  }


  return isValid
}


export default router
