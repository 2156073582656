// Containers
const TheContainer = () =>
    import ('../containers/TheContainer')

// Views
const Dashboard = () =>
    import ('../views/Dashboard')


// Views - Pages
const Login = () =>
    import ('../views/pages/Login')

// Users
const Users = () =>
    import ('../views/users/master')
const User = () =>
    import ('../views/users/detail')
    
const Worksheets = () =>
    import ('../views/worksheets/master')

const Questions = () =>
    import ('../views/questions/master')


const Question = () =>
    import ('../views/questions/detail')


const Deduplication = () =>
    import ('../views/deduplication/deduplication')


const DeduplicationHistory = () =>
    import ('../views/deduplication/deduplicationHistory')



const DeletedDeduplication = () =>
    import ('../views/deduplication/deduplicationDelete')


const BulkTagging = () =>
    import ('../views/bulkTagging/master')


const CourseTags = () =>
    import ('../views/tags/courses')


const Tags = () =>
    import ('../views/settings/tags')

const Tag = () =>
    import ('../views/settings/tag')

const Levels = () =>
    import ('../views/settings/levels')

const Subjects = () =>
    import ('../views/settings/subjects')


const Aliases = () =>
    import ('../views/settings/aliases')


const Logs = () =>
    import ('../views/settings/logs')

// const Topics = () =>
//     import ('../views/settings/topics')

// const Subtopics = () =>
//     import ('../views/settings/sub_topics')

const routes = configRoutes();
function configRoutes() {
        return [
            {
                path: '/login',
                name: 'login',
                component: Login,
                meta: { requireAuth: false, functionName: 'login' }
            },
            {
                path: '/',
                redirect: '/dashboard',
                name: 'Home',
                component: TheContainer,
                children: [{
                        path: 'dashboard',
                        name: 'Dashboard',
                        component: Dashboard,
                        meta: { requireAuth: true, functionName: 'dashboard' }
                    },
                    {
                        path: 'worksheets',
                        name: 'Worksheets',
                        component: Worksheets,
                        meta: { requireAuth: true, functionName: 'worksheets' }

                    },
                    {
                        path: 'questions',
                        name: 'Questions',
                        component: Questions,
                        meta: { requireAuth: true, functionName: 'questions' }
                    },
                    {
                        path: 'question/:id',
                        name: 'Questions',
                        component: Question,
                        meta: { requireAuth: true, functionName: 'question' }
                    },
                    {
                        path: 'deduplications',
                        name: 'Possible Duplicates',
                        component: Deduplication,
                        meta: { requireAuth: true, functionName: 'deduplication' }
                    },
                    {
                        path: 'deduplication-history',
                        name: 'Deleted Duplicates',
                        component: DeduplicationHistory,
                        meta: { requireAuth: true, functionName: 'deduplicationHistory' }
                    },
                    {
                        path: 'deduplication-deleted',
                        name: 'Not Duplicates',
                        component: DeletedDeduplication,
                        meta: { requireAuth: true, functionName: 'deletedDeduplication' }
                    },
                    {
                        path: '/questions/bulk-tagging',
                        name: 'bulk-tagging',
                        component: BulkTagging,
                    },
                    {
                        path: 'users',
                        name: 'Users',
                        component: Users,
                        meta: { requireAuth: true, functionName: 'users' }
                    },
                    // {
                    //     path: 'users',
                    //     meta: {
                    //         label: 'Users'
                    //     },
                    //     component: {
                    //         render(c) {
                    //             return c('router-view')
                    //         }
                    //     },
                    //     children: [{
                    //             path: '',
                    //             name: 'Users',
                    //             component: Users,
                    //             meta: { requireAuth: true, functionName: 'Users' }

                    //         },
                    //         {
                    //             path: ':id',
                    //             meta: {
                    //                 label: 'User Details'
                    //             },
                    //             name: 'User',
                    //             component: User,
                    //             meta: { requireAuth: true, functionName: 'User' }

                    //         }
                    //     ]
                    // },
    
                    {
                        path: '/settings',
                        redirect: '/settings/subjects',
                        name: 'Settings',
                        component: {
                            render(c) { return c('router-view') }
                        },
                        children: [
                            {
                                path: 'logs',
                                name: 'logs',
                                component: Logs,
                                meta: { requireAuth: true, functionName: 'activityLogs' }

                            },
                            {
                                path: 'courses',
                                name: 'courses',
                                component: CourseTags,
                                meta: { requireAuth: true, functionName: 'worksheets' }

                            },
                            {
                                path: 'tags',
                                name: 'tags',
                                component: Tags,
                                meta: { requireAuth: true, functionName: 'tags' }

                            },
                            {
                                path: 'aliases',
                                name: 'Aliases',
                                component: Aliases,
                                meta: { requireAuth: true, functionName: 'aliasess' }

                            },
                            {
                                path: 'tag/:id',
                                name: 'tag',
                                component: Tag,
                                meta: { requireAuth: true, functionName: 'tag' }

                            },
                            // {
                            //     path: 'levels',
                            //     name: 'levels',
                            //     component: Levels
                            // },
                            // {
                            //     path: 'subjects',
                            //     name: 'subjects',
                            //     component: Subjects
                            // },
                            // {
                            //     path: 'Topics',
                            //     name: 'Topics',
                            //     component: Topics
                            // },
                            // {
                            //     path: 'sub-topics',
                            //     name: 'sub-topics',
                            //     component: Subtopics
                            // }
                        ]
                    }
                ]
            }
        ]
}

export default routes;