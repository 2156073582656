import axios from 'axios';
import { questions, bulk_questions } from '../../api';
import Cookies from 'js-cookie'
import Vue from 'vue'

// state
const state = {
    questions: [],
    total_tagged:null,
	total_untagged:null,
    total:null,
    filters:{},
    selectedQuestion: null,
    selectedQuestions: [],
    tags_filter:{},
	per_page:0,
}

// getters
const getters = {

}

// mutations
const mutations = {
    FETCH(state, data) {
        if(data){
            state.questions = data.questions;
            state.total_tagged = data.tagged_count;
			state.total_untagged = data.untagged_count;
            state.total = data.question_count;
			state.per_page = data.per_page;

            let filters = data.filters
            let result = []
            Object.keys(filters)
            .forEach(function eachKey(key) { 
            // alerts value
           // $isDisabled: true
               filters[key].forEach(function (element) {
                   // console.log(element)
                    if(element.active)
                    {
                        element.$isDisabled = false
                    }
                    else
                    {
                        element.$isDisabled = true
                    }
                });
                let data = {
                    name:key,
                    items:filters[key],
                    selected_filter:filters[key].filter(obj => {
                        return obj.selected === true
                      })
                }
                result.push(data)
            });
           state.filters = result
        }
    },
    FETCH_SELECTED(state, selectedQuestion) {
        state.selectedQuestion = selectedQuestion;
    },
    FETCH_SELECTED_QUESTIONS(state, data) {
        if(data){
            state.selectedQuestions = data.questions;

            let filters = data.filters
            let result = []
            Object.keys(filters)
            .forEach(function eachKey(key) { 
                let data = {
                    name:key,
                    items:filters[key],
                    selected_filter:filters[key].filter(obj => {
                        return obj.selected === true
                      })
                }
                result.push(data)
            });
           state.tags_filter = result
           
        }
    },
    FETCH_VETSTATUS(state, vetStatus) {
        state.vetStatus = vetStatus;
    },
    FETCH_LATEST_QUESTION(state, data) {
        for (var index = 0; index < state.questions.length; index++) {
            if (state.questions[index].id === data.id) {
                state.questions[index].question_text = data.question_text;
                state.questions[index].quiz_url = data.quiz_url;
                state.questions[index].correct_comments = data.correct_comments;
                state.questions[index].incorrect_comments = data.incorrect_comments;
                state.questions[index].neutral_comments = data.neutral_comments;
                state.questions[index].answers = data.answers;
                state.questions[index].vetted = data.vetted;
                state.questions[index].math_cloner_id = data.math_cloner_id;
                state.questions[index].math_cloner_source_question = data.math_cloner_source_question;
                break;
            }
        }
    }
}

// actions
const actions = {
    fetch({ commit }, data) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        var query = `${questions}`;
        if (data.query.page && data.filter) {
            //query += `?page=${data.query.page}&filters=${data.filter}`;
            query += `?page=${data.query.page}`;

        } else if (data.query.page) {
            query += `?page=${data.query.page}`;
        } 
        // else if (data.filter) {
        //     query += `?filters=${data.filter}`;
        // }

        return axios.post(query, {filters:data.filter})
            .then(response => commit('FETCH', response.data))
            .catch();
    },
    async fetchSelectedQuestion({ commit }, id) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        return await axios.get(`${questions}/${id}`)
            .then(response => commit('FETCH_SELECTED', response.data))
            .catch(error => {
                if (error.response) {
                    Vue.notify({
                        group: 'notify',
                        type: 'error',
                        text: error.response.data.error.message,
                        max: 5,
                        duration: 5000,
                        speed: 300
                    })
                } else {
                    console.log("Problem submitting New Post", error);
                }
            });
    },
    async fetchSelectedQuestions({ commit }, data) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        return await axios.get(`${bulk_questions}?`, { params: { question_ids: data.selected }})
            .then(response => commit('FETCH_SELECTED_QUESTIONS', response.data))
            .catch();
    },
    async updateSelectedQuestion({ commit }, data) {
        return await axios.post(`${questions}/${data.id}`, {
            tag_items: data.tag_items
        })
            .then((response) => {
                commit('FETCH_SELECTED', response.data)
                Vue.notify({
                    group: 'notify',
                    type: 'success',
                    text: 'Tag has been updated',
                    max: 5,
                    duration: 5000,
                    speed: 300
                });
            });
    },
    async updateSelectedQuestions({ commit }, data) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        return await axios.post(`${bulk_questions}`, {
            question_ids: data.questions_ids,
            filters: data.filters
        }).then(response => {
            commit("FETCH_SELECTED", response.data);
            Vue.notify({
                group: "notify",
                type: "success",
                text: "Tags has been updated",
                max: 5,
                duration: 5000,
                speed: 300
            });
        })
    },
    async updateVetStatus({ commit }, data) {
        return await axios.patch(`${questions}/${data.id}/vetted`, {
            vetted: data.vetted
        }).then((response) => {
            commit('FETCH_VETSTATUS', response.data)
            Vue.notify({
                group: 'notify',
                type: 'success',
                text: 'Vet Status has been updated',
                max: 5,
                duration: 5000,
                speed: 300
            });
        });
    },
    async syncQuestion({ commit }, data) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        return axios.post(`${questions}/${data.id}/resync`).then((response) => {
            commit('FETCH_LATEST_QUESTION', response.data)
            Vue.notify({
                group: 'notify',
                type: 'success',
                text: 'Question Content Sync Successful',
                max: 5,
                duration: 5000,
                speed: 300
            });
        });
    },
}

export {
    state,
    getters,
    actions,
    mutations
}