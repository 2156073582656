import axios from 'axios'
import Cookies from 'js-cookie'
import * as types from '../mutation-types'
import { getAuthUser, logout, oauthUrl } from '../../api';
import router from '../../router';


// axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');

// state
const state = {
  user: Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null,
  token: Cookies.get('token')
}

// getters
const getters = {
  user: state => state.user,
  token: state => state.token,
  check: state => state.user !== null
}

// mutations
const mutations = {
  [types.SAVE_TOKEN](state, { token, remember }) {
    state.token = token
    Cookies.set('token', token, { expires: 7 })
    // Cookies.set('token', token, { expires: remember ? 365 : null })
  },

  [types.FETCH_USER_SUCCESS](state, { user }) {
    state.user = user
    Cookies.set('user', user, { expires: 7 })
  },

  [types.FETCH_USER_FAILURE](state) {
    state.token = null
    Cookies.remove('token')
    Cookies.remove('user')
  },

  [types.LOGOUT](state) {
    state.user = null
    state.token = null
    Cookies.remove('token')
    Cookies.remove('user')
  },

  [types.UPDATE_USER](state, { user }) {
    state.user = user
    Cookies.set('user', user, { expires: 7 })
  }
}

// actions
const actions = {
  saveToken({ commit, dispatch }, payload) {
   // alert("A")

    axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
    commit(types.SAVE_TOKEN, payload)
  },

  async fetchUser({ commit }) {
    try {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
      const { data } = await axios.get(getAuthUser)

      commit(types.FETCH_USER_SUCCESS, { user: data })
    } catch (e) {
      commit(types.FETCH_USER_FAILURE)
      router.push("/login")
    }
  },

  updateUser({ commit }, payload) {
    commit(types.UPDATE_USER, payload)
  },

  async logout({ commit }) {
    try {
      await axios.post(logout)
    } catch (e) { }

    commit(types.LOGOUT)
  },

  async fetchOauthUrl(ctx, { provider }) {
    const { data } = await axios.post(`${oauthUrl}/${provider}`)

    return data.url
  }
}

export {
  state,
  getters,
  actions,
  mutations
}