import axios from 'axios';
import { tags } from '../../api';
import Vue from 'vue'
import Cookies from 'js-cookie'

// state
const state = {
    data: [],
    selectedTag: null
}

// getters
const getters = {

}

// mutations
const mutations = {
    FETCH_TAGS(state, tags) {
        state.data = tags;
    },
    FETCH_SELECTED(state, selectedTag) {
        state.selectedTag = selectedTag;
    }
}

// actions
const actions = {
    
    async fetchTagItems({ commit }, id) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        return await axios.get(`${tags}/${id}/tag-items`)
            .then(response => commit('FETCH_SELECTED', response.data))
            .catch(error => {
                if (error.response) {
                    Vue.notify({
                        group: 'notify',
                        type: 'error',
                        text: error.response.data.error.message,
                        max: 5,
                        duration: 5000,
                        speed: 300
                    })
                } else {
                    console.log("Problem submitting New Post", error);
                }
            });
    },
    async createTag({ commit }, data) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        return await axios.post(`${tags}`, {
            name: data.name,
            description: data.description,
            type: data.type,
            // tag_items: data.tag_items
        })
            .then((response) => {
                // commit('FETCH_SELECTED', response.data)
//                console.log(response)
                Vue.notify({
                    group: 'notify',
                    type: 'success',
                    text: 'Tag has been created',
                    max: 5,
                    duration: 5000,
                    speed: 300
                });
            });
    },
    async updateTagItem({ commit }, data) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        return axios.patch(`${tags}/${data.id}/tag-items/${data.item_id}`, {
            name: data.name,
            description: data.description,
        })
            .then((response) => {
                // commit('FETCH_SELECTED', response.data)
                //dispatch('fetchTagItems', data.id);
                Vue.notify({
                    group: 'notify',
                    type: 'success',
                    text: 'Tag has been updated',
                    max: 5,
                    duration: 5000,
                    speed: 300
                });
            });
    },
    async updateSelectedTag({ commit }, data) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        return axios.patch(`${tags}/${data.id}`, {
            name: data.name,
            description: data.description,
            type: data.type,
            tag_items: data.tag_items
        })
            .then((response) => {
                commit('FETCH_SELECTED', response.data)
                Vue.notify({
                    group: 'notify',
                    type: 'success',
                    text: 'Tag has been updated',
                    max: 5,
                    duration: 5000,
                    speed: 300
                });
            });
    },
    async fetchTags({ commit }, flag) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        return await axios.get(tags)
            .then((response) => {
                //console.log(response);
                // for (var prop in response.data.data) {
                //     if (response.data.data.hasOwnProperty(prop) && flag) {
                //         response.data.data[prop].tag_items.push(
                //             { id: 0, tag_id: response.data.data[prop].id, text: 'Empty' }
                //         );
                //     }
                // }
//                console.log(response.data)
                commit('FETCH_TAGS', response.data)
            })
            .catch((error) => {
                if (error.response) {
                    Vue.notify({
                        group: 'notify',
                        type: 'error',
                        text:"Error",
                        //text: error.response.data.error.message,
                        max: 5,
                        duration: 5000,
                        speed: 300
                    })
                } else {
                    console.log("Problem submitting New Post", error);
                }
            });
    },
    async deleteTag({ commit, dispatch }, id) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        await axios.delete(`${tags}/${id}`)
            .then((response) => {
                commit('FETCH_TAGS', response.data)
                // dispatch('fetchTags');
                Vue.notify({
                    group: 'notify',
                    type: 'success',
                    text: 'Tag has been deleted',
                    max: 5,
                    duration: 5000,
                    speed: 300
                });
            })
            .catch((error) => {
                if (error.response) {
                    Vue.notify({
                        group: 'notify',
                        type: 'error',
                        text: error.response.data.error.message,
                        max: 5,
                        duration: 5000,
                        speed: 300
                    })
                } else {
                    console.log("Problem submitting New Post", error);
                }
            });
    },
    async editTag({ dispatch }, tag) { //todo
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        await axios.patch(`${tags}/${tag.id}`, {
            title: tag.title
        })
            .then(() => dispatch('fetchTags'));
    },
    async createTagItem({ commit, dispatch }, data) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        axios.post(`${tags}/${data.id}/tag-items`, {
            name: data.name,
            description: data.description
        })
            .then(() => {
                dispatch('fetchTagItems',data.id);
                Vue.notify({
                    group: 'notify',
                    type: 'success',
                    text: 'Tag Item has been created',
                    max: 5,
                    duration: 5000,
                    speed: 300
                });
            })
            .catch((error) => {
                if (error.response) {
                    Vue.notify({
                        group: 'notify',
                        type: 'error',
                        text: error.response.data.message,
                        max: 5,
                        duration: 5000,
                        speed: 300
                    })
                } else {
                    console.log("Problem submitting New Post", error);
                }
            });
    },
    async deleteTagItem({ commit, dispatch }, data) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        await axios.delete(`${tags}/${data.id}/tag-items/${data.item_id}`)
            .then((response) => {
                dispatch('fetchTagItems',data.id);
                Vue.notify({
                    group: 'notify',
                    type: 'success',
                    text: 'Tag Item has been deleted',
                    max: 5,
                    duration: 5000,
                    speed: 300
                });
            })
            .catch((error) => {
                if (error.response) {
                    Vue.notify({
                        group: 'notify',
                        type: 'error',
                        text: error.response.data.error.message,
                        max: 5,
                        duration: 5000,
                        speed: 300
                    })
                } else {
                    console.log("Problem submitting New Post", error);
                }
            });
    },
    async addTag({ commit, dispatch }, data) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        axios.post(`${tags}`, {
            name: data.name,
            tag_items: data.tag_items
        })
            .then(() => {
                dispatch('fetchTags');
                Vue.notify({
                    group: 'notify',
                    type: 'success',
                    text: 'Tag has been created',
                    max: 5,
                    duration: 5000,
                    speed: 300
                });
            })
            .catch((error) => {
                if (error.response) {
                    Vue.notify({
                        group: 'notify',
                        type: 'error',
                        text: error.response.data.error.message,
                        max: 5,
                        duration: 5000,
                        speed: 300
                    })
                } else {
                    console.log("Problem submitting New Post", error);
                }
            });
    }
}

export {
    state,
    getters,
    actions,
    mutations
}