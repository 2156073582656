import Vue from 'vue'

// Global function for the displayQuestionAndAnswers.js
/*
  Description - To find out the correct answer from the answer facts
  Correct Answer = Answer fact with weight 100
*/
function getAnswerFactWeightHundred(answers){
    let result = '';
    for(let i = 0; i < answers.length; i++){
      if(answers[i].weight == 100){
        result = answers[i];
      }
    }

    return result;
}
/*
  For Single answer question
  Description - To find out correct answer obj from the answer_facts and answer_options 
  answer_facts include the correct answer (weight == 100) and the quiz_question_answer_id
  answer_options include the list of all options for the answer (answer_text, html etc)
*/
function  getCorrectAnswerObj(result, answer_options){
    let correct_answer = ''
    for(let i = 0; i < answer_options.length; i++){
        if(result.quiz_question_answer_id == answer_options[i].id){
        correct_answer = answer_options[i]
        }
    }

    return correct_answer
}
/*
  For multiple answers question , this will return the list of correct answers in array
  Description - To find out correct answers from the answer_facts and answer_options 
  answer_facts include the correct answer (weight == 100) and the quiz_question_answer_id
  answer_options include the list of all options for the answer (answer_text, html etc)
*/
function getCorrectAnswers(question){
    let correct_answers = []
    let answer_fact = question.answers
    for(let i = 0; i < answer_fact.length; i++){
        if(answer_fact[i].weight == 100){

        let obj = answer_fact[i];
        let txt = obj.text
        let blank_id = obj.blank_id
        
        answer_fact[i].answer_text = txt
        answer_fact[i].blank_id = blank_id
        correct_answers.push(answer_fact[i])
        }
    }


    return correct_answers
}

/* The functions written below can be used in any of the vue components without requiring them to import in*/
const MyPlugin = {
    install(Vue, options) {
    // Get dropdown components for multiple dropdowns question type
  
    Vue.prototype.getContentForFillMultipleDropdowns = (question) => {
        var question_html = question.question_text // Get the question text from question obj
        var url = "https://tll.instructure.com"; // Live Canvas url

        // Use regx to replace all the links starts with src="/" to src="https://tll.instructure.com/"
        question_html = question_html.replace(
                new RegExp('src="/', "g"),
                  'style="max-width: 40vw; height: auto;" src="' + url + "/"
                );

        
        let answer_options = question.answers; // Get the answer_options from the question obj

        let correct_answer_fact = getCorrectAnswers(question) // The the correct answers for multiple dropdowns
        let correct_answers = []
        for(let i = 0; i < correct_answer_fact.length; i++){
            let compare_text = "[" + correct_answer_fact[i].blank_id +"]"

            if(question_html.indexOf(compare_text)){
                let obj = {index:question_html.indexOf(compare_text), 
                            blank_id:correct_answer_fact[i].blank_id, 
                            quiz_question_answer_id:correct_answer_fact[i].quiz_question_answer_id,
                            answer_text:correct_answer_fact[i].answer_text
                            }
                correct_answers.push(obj)
            }
        }

        // Get the group of correct answers by blank_id ("-" , "a")
        var grouped = _.groupBy(answer_options, answer => answer.blank_id);
        for(let i = 0; i < answer_options.length; i++){
            if(answer_options[i].blank_id){
        
            let dropdown_options = grouped[answer_options[i].blank_id]
            let compare_text = "[" + answer_options[i].blank_id +"]"


            let html_start = '<select class="mb-3" style="max-width:100%">';
            let option_html = "<option :value='[Select]'>[Select]</option>";
            
            for(let j = 0; j< dropdown_options.length; j++){
                option_html = option_html + '<option :value='+ dropdown_options[j].text + '>'+dropdown_options[j].text+'</option>'
            }
            let html_end = "</select>";

            let txt = html_start + option_html+html_end

            
                question_html = question_html.replace(compare_text, txt)

            
            }
        }

        // Generate the question_html text
        question_html = question_html + '<div style="margin-top:10px"><div style="color:green; font-weight:bold">Correct Answer:</div>' 

        // Sort the correct answers
        correct_answers.sort(function (a, b) {
            return a.index - b.index;
        });

        // Display the correct answers
        for(let j = 0; j < correct_answers.length; j++){       
            question_html = question_html + '<div>' + (j+1) + ". " + correct_answers[j].answer_text + '</div>'
        }
        + '</div>'

        return question_html;
    },
    // Get the single correct answer
    Vue.prototype.getCorrectAnswer = (question) => {
        
        let correct_answer = '';
        let answer_options = question.answers // Get answer_options from question obj

        let result = getAnswerFactWeightHundred(answer_options) // Get the correct answer fron answer fact (weight == 100)

        if(result)
        {
          // Get the correct answer_obj
          correct_answer = result.html ? result.html : result.text
          var url = "https://tll.instructure.com";

             correct_answer = correct_answer.replace(
              new RegExp('src="/', "g"),
              'style="max-width: 40vw; height: auto;" src="' + url + "/"
            );
        }


        return correct_answer
     },
      // Get the question content with multiple blanks
      Vue.prototype.getContentForFillMultipleBlanks = (question) => {
        var question_html = question.question_text
        var url = "https://tll.instructure.com";
  
        question_html = question_html.replace(
                new RegExp('src="/', "g"),
                'style="max-width: 40vw; height: auto;" src="' + url + "/"
              );
        
        let answers = question.answers
        let correct_answers = []
          
        for(let i = 0; i< answers.length; i++){
           if(answers[i].blank_id && answers[i].text){
             let compare_text = "[" + answers[i].blank_id +"]"
             
             if(question_html.indexOf(compare_text) != -1 )
             {
               let obj = {index:question_html.indexOf(compare_text), text:answers[i].text,comment:answers[i].comments}
               correct_answers.push(obj)
             }
             question_html = question_html.replace(compare_text, '<input type="text" name="fill_in_blank" style="max-width: 150px; height:25px">')
           }
         }
  
        correct_answers.sort(function (a, b) {
          return a.index - b.index;
        });
  
        question_html = question_html + '<div style="margin-top:10px"><div style="color:green; font-weight:bold">Correct Answer:</div>' 
        
        for(let j = 0; j < correct_answers.length; j++){
          let answer_comments="";
          if(correct_answers[j].comment!== ""){
            answer_comments ='<div color="primary">Answer Comments: '+correct_answers[j].comment+'</div>';
          }
          let correct_answer = correct_answers[j].html ? correct_answers[j].html : correct_answers[j].text
          question_html = question_html + '<div>' + (j+1) + ". " + correct_answer +" "+ answer_comments+ '</div>'
        }
        + '</div>'
  
        return question_html
     },
     // Get the question context with matching answers (right and left dropdowns)
      Vue.prototype.getmatchingAnswerOptions = (question) => {
        let ans_options = question.answers
        let selection_list = ["[Choose]"]
        let add_options = []

        // Forming the selection list for each dropdown
        for(let i = 0; i < ans_options.length; i++){
            let option = ans_options[i];
            if(option.answer_match_right){
            selection_list.push(option.answer_match_right)

            let additional_options = []
            if(option.matching_answer_incorrect_matches){
              additional_options = option.matching_answer_incorrect_matches.split('\n')
            }
            
            for(let j =0; j< additional_options.length; j++)
            {
                if(!add_options.includes(additional_options[j])){
                add_options.push(additional_options[j])
                }
            }
            }
        }

        let results = selection_list.concat(add_options)


        return results;
      },
      // Get Question Content
      Vue.prototype.getContent = (html) => {
        var url = "https://tll.instructure.com";

        // Replace links with url 
        let question_html = html.replace(
            new RegExp('src="/', "g"),
            'style="max-width: 40vw; height: auto;" src="' + url + "/"
        );


        let res = question_html.match(/(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/g);

        if(res)
        {
            let iframe = res[0]
            if(iframe.match(/(?<=src=").*?(?=[\"])/g))
            {
            
                let txt = iframe.match(/(?<=src=").*?(?=[\"])/g)[0]

                let rep_str = "<div><a href='" + txt +"' target='_blank'>iFrame - External Link</a></div>"

                question_html = question_html.replace(
                new RegExp('(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))', "g"),
                rep_str 
                );
            }
        }

        return question_html
      },
      // Retrieve Correct Answer for Multiple
      Vue.prototype.getCorrectAnswersForMultiple = (question) => {
        let correct_answers = []

        correct_answers = getCorrectAnswers(question);


        return correct_answers
      } 
    },
  }
Vue.use(MyPlugin)