import axios from "axios";
import { activity } from "../../api";
import Cookies from 'js-cookie'


// state
const state = {
    logs: [],
	total:0
};

// getters
const getters = {};

// mutations
const mutations = {
    FETCH_LOGS(state, logs) {
        state.logs = logs.data;
		state.total = logs.total;
    },
};

// actions
const actions = {
    fetchLogs({ commit }, data) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('token');
        var query = `${activity}`;
        if (data.query.page) {
            query += `?page=${data.query.page}`;

        } else if (data.query.page) {
            query += `?page=${data.query.page}`;
        } 

        return axios.get(query)
            .then(response => commit('FETCH_LOGS', response.data))
            .catch();
    },
};

export { state, getters, actions, mutations };
